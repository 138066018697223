@import 'src/scss/variables';

.streaming-list {
  display: flex;
  flex-direction: column;
  grid-gap: $gapL;


  &--exist {
    @media all and (min-width: $desktop) {
      align-items: flex-end;
    }
  }

  &--non-exist {

  }
}
@import 'src/scss/variables';

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;

  @media all and (min-width: $tablet) {
    padding: unset;
    max-width: 640px;
  }

  @media all and (min-width: $desktop) {
    max-width: 940px;
  }
}
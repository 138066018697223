@import 'src/scss/variables';

.about {
  background-color: white;
  border-radius: $borderRadiusM;
  padding: $paddingM;
  display: flex;
  flex-direction: column;
  grid-gap: $gapL;

  &__img {
    margin-left: -1rem;
    margin-top: -1rem;
    margin-bottom: 0.5rem;
    min-width: calc(100% + 2rem);
  }
}
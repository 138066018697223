@import 'src/scss/variables';

.streaming {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: $secondaryButton;
  border-radius: $borderRadiusS;
  cursor: pointer;

  @media all and (min-width: $desktop) {
    width: 212px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    padding: 20px;
    grid-gap: $gapXS;
  }

  &__logo {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: unset;
      max-height: unset;
      height: 29px;
      width: 29px;
    }

    &:before {
      position: absolute;
      content: "";
      width: 1px;
      background-color: $footerLabel;
      opacity: 0.1;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
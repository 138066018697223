@import 'src/scss/variables';

.avatar {
  overflow: hidden;
  display: flex;
  align-items: center;

  img {
    border-radius: $borderRadiusS;
  }
}
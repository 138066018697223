@import 'src/scss/variables.scss';

.svgMap-map-wrapper {
  background: #FFFFFF;
  border: 1px solid #eee;
  border-radius: 10px;

  .svgMap-country {
    cursor: pointer;
    stroke: transparent;
  }
}


@import "src/scss/variables";

.see-more {
  display: flex;
  flex-direction: column;
  grid-gap: $gapM;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gapL;


    @media all and (min-width: $desktop) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media all and (min-width: $desktop) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
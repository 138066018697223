@import 'src/scss/variables';

.blog {
  background-color: white;
  border-radius: $borderRadiusM;
  padding: $paddingM;
  width: 100%;
  height: fit-content;
  grid-gap: $gapL;
  display: flex;
  flex-direction: column;

  &__list {
    .divider:last-of-type {
      display: none;
    }
  }

  .preview {
    display: flex;
    flex-direction: column;

    &__name {
      margin-bottom: 8px;
    }

    &__text {
      height: 60px;
      position: relative;
      overflow: hidden;

      p {
        max-height: 100%;
      }

      &:after {
        position: absolute;
        width: 100%;
        content: "";
        bottom: 0;
        left: 0;
        height: 50px;
        background: linear-gradient(to bottom, transparent 0%, white 90%);
      }
    }
  }

  &__item {
    display: flex;
    grid-gap: $gapL;
    flex-direction: column;

    @media all and (min-width: $tablet) {
      flex-direction: row;
    }
  }

  &__cover {
    overflow: hidden;
    flex-shrink: 0;

    img {
      border-radius: $borderRadiusXS;
      width: 100%;
      height: 100%;
    }

    @media all and (min-width: $tablet) {
      img {
        width: 183px;
        height: 183px;
      }
    }
  }
}
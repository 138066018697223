@import 'src/scss/variables';

.filter-list {
  background-color: white;
  border-radius: $borderRadiusS;

  position: sticky;
  top: 1rem;
  left: 0;
  overflow: hidden;
  max-height: calc(100dvh - 104px - 1rem);


  @media all and (min-width: $desktop) {
    width: 200px;
  }

  &__actions {
    padding: $paddingM;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: $gapL;
    flex-direction: column;

    position: sticky;
    bottom: 0;
    left: 0;
    background-color: white;

    & > * {
      width: 100%;
    }
  }
}
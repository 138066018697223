// typography
$fontWeight: 700;
// XS
$fontSizeXS: 12px;
$fontLineHeightXS: 15px;
// S
$fontSizeS: 14px;
$fontLineHeightS: 20px;
// M
$fontSizeM: 16px;
$fontLineHeightM: 20px;
// L
$fontSizeL: 18px;
$fontLineHeightL: 20px;
// XL
$fontSizeXL: 24px;
$fontLineHeightXL: 30px;
// XXL
// Desktop
$fontSizeXXLDesktop: 40px;
$fontLineHeightXXLDesktop: 48px;
// Mobile
$fontSizeXXLMobile: 28px;
$fontLineHeightXXLMobile: 30px;

// ===========
// colors
$black: #292622;
$white: #FFFFFF;


// buttons
$primaryButton: $black;
$secondaryButton: #F3F3F3;
$tertiaryButton: transparent;

$primaryButtonHover: rgba($black, 0.9);
$secondaryButtonHover: #EBE9E8;
$tertiaryButtonHover: #EBE9E8;

$primaryButtonActive: rgba($black, 0.8);
$secondaryButtonActive: #E1E1E1;
$tertiaryButtonActive: #E1E1E1;
// ===========

$cardBgColor: #DCDCDC;
$supportBackground: #F6F6F6;
$contentBackground: #F3F4F8;

$divider: #F1F1F1;
$links: #008DF2;
$activeMenu: #F8F8F8;
$postBackground: #FBFBFB;
$albumRelease: #363636;
$footerLabel: #8F8F8F;
$tags: #747894;

$bodyBackgroundColor: rgba(243, 244, 248, 1);
$dividerColor: rgba(241, 241, 241, 1);

// header
$headerHeight: 60px;

// paddings
$paddingXS: 8px;
$paddingS: 10px;
$paddingM: 1rem;
$paddingL: 1.5rem;

// margins
$headerMargin: 28px 0;

// transitions
$shortTransition: 125ms;

// gaps
$gapXS: 3px;
$gapS: 6px;
$gapM: 12px;
$gapL: 16px;
$gapXL: 20px;
$gapXXL: 24px;

// navLink
$linkActive: black;
$linkInactive: rgba(138, 138, 138, 1);

// radius
$borderRadiusXS: 8px;
$borderRadiusS: 10px;
$borderRadiusM: 12px;
$borderRadiusL: 1rem;


// breakpoints
$tablet: 834px;
$desktop: 1440px;

// media
$desktop: 1440px;
$desktopContainerWidth: 940px;

@import 'src/scss/variables';

.socials {
  display: flex;
  grid-gap: $gapM;

  & > a img {
    width: 28px;
    height: 28px;
    //background-color: black;
    border-radius: 50%;
    cursor: pointer;
  }

  &--row {
    flex-direction: row;
  }

  &--column {
    flex-direction: column;
  }
}
@import 'src/scss/variables';

.people {
  background-color: white;
  border-radius: $borderRadiusM;
  padding: $paddingM;

  &__body {
    display: grid;
    justify-content: space-evenly;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);

    @media all and (min-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media all and (min-width: $desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
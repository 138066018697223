@use 'src/scss/mixins';
@import 'src/scss/variables';

.button {
  @include mixins.displayFlex();

  grid-gap: 6px;

  border-radius: $borderRadiusS;
  transition: $shortTransition;
  cursor: pointer;

  width: fit-content;

  background-color: transparent;
  border: none;
  outline: none;

  padding: 12px 28px;

  @include mixins.fontVariant(s);
  font-weight: 500;
  text-align: center;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &--primary {
    background-color: $primaryButton;
    color: $white;

    &:not(:disabled):hover {
      background-color: $primaryButtonHover;
    }

    &:not(:disabled):active {
      background-color: $primaryButtonActive;
    }
  }

  &--secondary {
    background-color: $secondaryButton;

    &:not(:disabled):hover {
      background-color: $secondaryButtonHover;
    }

    &:not(:disabled):active {
      background-color: $secondaryButtonActive;
    }
  }

  &--tertiary {
    background-color: transparent;

    &:not(:disabled):hover {
      background-color: $tertiaryButtonHover;
    }

    &:not(:disabled):active {
      background-color: $tertiaryButtonActive;
    }
  }

  &--icon {
    padding: 1rem;
    background-color: $secondaryButton;

    &:not(:disabled):hover {
      background-color: $secondaryButtonHover;
    }

    &:not(:disabled):active {
      background-color: $secondaryButtonActive;
    }
  }

  &--full-width {
    width: 100%;
  }
}
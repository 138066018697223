@import 'src/scss/variables';

.support {
  display: flex;
  align-items: center;

  width: 100%;

  border-radius: $borderRadiusS;

  flex-direction: column;
  justify-content: space-between;
  grid-gap: $gapL;

  @media all and (min-width: $desktop) {
    flex-direction: row;
  }

  & div {
    text-align: center;
    @media all and (min-width: $desktop) {
      text-align: left;
    }
  }

  & div:first-child {
    display: flex;
    flex-direction: column;
    grid-gap: $gapL;
    align-items: center;

    @media all and (min-width: $desktop) {
      align-items: flex-start;
    }
  }

  & div:last-child {
    display: flex;
    align-items: center;
  }

  & .socials {
    justify-content: center;
    @media all and (min-width: $desktop) {
      justify-content: flex-start;
    }
  }
}
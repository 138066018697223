@import 'src/scss/variables';

.album-card {
  display: flex;
  flex-direction: column;
  grid-gap: $gapS;
  cursor: pointer;

  &__description {
    display: flex;
    flex-direction: column;
    grid-gap: $gapXS;
  }

  &__image {
    border-radius: $borderRadiusXS;
  }

  &__name {
    font-weight: 700;
  }

  &__author {
    font-weight: 400;
  }
}
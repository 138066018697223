@import 'src/scss/variables';

.library {
  background-color: white;
  border-radius: $borderRadiusM;
  padding: $paddingM;
  height: fit-content;
  width: 100%;

  &__albums {
    display: grid;
    grid-gap: $gapL;
    grid-template-columns: repeat(2, 1fr);

    @media all and (min-width: $tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media all and (min-width: $desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@import 'src/scss/variables';

.filter {

  &__type {
    user-select: none;
    font-weight: 500;
    font-size: $fontSizeM;
    padding: $paddingM;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    position: sticky;
    top: 0;
    left: 0;

    background-color: white;

    &:not(last-of-type) {
      border-bottom: 1px solid $dividerColor;
    }

    img {
      transition: $shortTransition;
    }

    &--open {
      img {
        transform: rotate(0deg);
      }
    }

    &--closed {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    &--open {
      overflow: auto;
      max-height: calc(100dvh - 104px - 52px - 52px - 52px - 52px - 136px);
    }

    &--closed {
      display: none;
    }
  }
}
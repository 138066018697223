@use 'src/scss/mixins';
@import 'src/scss/variables';

.nav-link {
  @include mixins.displayFlex();

  height: 100%;

  @include mixins.fontVariant(m);

  &--active {
    font-weight: 600;
  }

  &--burger {

    &:first-of-type {
      padding-top: 0;
    }

    padding: 1rem 0;
    @include mixins.fontVariant(m);
  }
}
@import "src/scss/variables";

.post {
  background-color: white;
  border-radius: $borderRadiusM;
  padding: $paddingL;

  &__album {
    display: grid;
    grid-gap: $gapM 0;
    grid-template-areas:
      "information"
      "cover"
      "authors";

    @media all and (min-width: $tablet) {
      grid-gap: 0 $gapXL;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr auto;
      grid-template-areas:
      "information cover"
      "authors cover";
    }

    @media all and (min-width: $desktop) {
      grid-template-columns: auto 1fr;
      grid-template-areas:
      "cover information"
      "cover authors";
    }
  }

  &__authors {
    grid-area: authors;
  }

  &__author {
    display: flex;
    flex-direction: column;
    grid-gap: $gapL;

    @media all and (min-width: $desktop) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__information {
    grid-area: information;
  }

  &__cover {
    grid-area: cover;
    border-radius: $borderRadiusXS;

    @media all and (min-width: $tablet) {
      height: 241px;
      width: 241px;
    }

    @media all and (min-width: $desktop) {
      height: 340px;
      width: 340px;
    }

  }

  &__date {
    color: grey;
  }

  &__text {
    margin-bottom: 24px;

    a {
      color: $links;
    }
  }

  &__information {
    flex-grow: 1;
  }

  &__streamings {
    display: grid;
    grid-gap: $gapM;

    @media all and (min-width: $desktop) {
      grid-template-columns: 1fr auto;
    }
  }
}
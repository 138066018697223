@import 'src/scss/variables';

.icon {
  width: 20px;
  height: 20px;

  &--left {
    order: -99999;
  }

  &--right {
    order: 99999;
  }
}
@import 'src/scss/variables';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 14px;
  margin: 1rem 0;
  border-radius: $borderRadiusL;
  background-color: white;
  padding: 1rem;


  &__copyright {
    color: $footerLabel;
  }
}
@use 'src/scss/mixins';
@import 'src/scss/variables';

.references {

  ol {
    column-count: 2;

    li {
      margin-left: 1rem;
      margin-bottom: 0.25rem;

      a {
        color: $links;
      }
    }

    li::marker {
      @include mixins.fontVariant(s);
    }
  }

  &__text {
    @include mixins.fontVariant(s);
  }
}
@import 'src/scss/variables';

.contacts {
  background-color: white;
  border-radius: $borderRadiusM;
  padding: $paddingM;

  &__heading {
    display: flex;
    flex-direction: column;
    grid-gap: $gapL;

    & > div {
      line-height: 1.2;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    grid-gap: $gapL;

    flex-direction: column;
    @media all and (min-width: $desktop) {
      flex-direction: row;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: $gapS;

    border-radius: $borderRadiusL;

    font-weight: 500;
    padding: 3rem;
    background-color: $secondaryButton;
    width: 100%;

    @media all and (min-width: $desktop) {
      flex-grow: 1;
      width: fit-content;
    }
  }

  &__description {
    @media all and (min-width: $desktop) {
      flex-grow: 2;

    }
  }
}
@use 'src/scss/mixins';
@import 'src/scss/variables';

.person {
  display: flex;
  flex-direction: column;
  border-radius: $borderRadiusS;
  background-color: $postBackground;
  padding: 1rem;
  border: 1px solid $divider;
  width: fit-content;

  &__name {
    @include mixins.fontVariant(s);
    margin: 10px 0 10px;
  }

  &__description {
    @include mixins.fontVariant(xs);
  }
  &__role {
    @include mixins.fontVariant(xs);
    color: $albumRelease;
    opacity: 0.64;
  }
}

@use 'src/scss/mixins';
@import 'src/scss/variables';

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    @include mixins.fontVariant(l);
    font-weight: 600;
  }
}
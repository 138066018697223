@import 'src/scss/variables';

@mixin fontVariant($variant) {
  @if $variant == xs {
    font-size: $fontSizeXS;
    line-height: $fontLineHeightXS;
  } @else if $variant == s {
    font-size: $fontSizeS;
    line-height: $fontLineHeightS;
  } @else if $variant == m {
    font-size: $fontSizeM;
    line-height: $fontLineHeightM;
  } @else if $variant == l {
    font-size: $fontSizeL;
    line-height: $fontLineHeightL;
  } @else if $variant == xl {
    font-weight: 500;
    font-size: $fontSizeXL;
    line-height: $fontLineHeightXL;
  } @else if $variant == xxl {
    font-size: $fontSizeXXLMobile;
    line-height: $fontLineHeightXXLMobile;
    font-weight: 600;
    @media all and (min-width: $desktop) {
      font-size: $fontSizeXXLDesktop;
      line-height: $fontLineHeightXXLDesktop;
    }
  } @else {
    @error "Unknown variant #{$variant}.";
  }
}

@mixin displayFlex($justifyContent: center, $alignItems: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}
@use './mixins';
@import './variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}

img {
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

a {
  color: unset;
  text-decoration: none;
  transition: $shortTransition;
  user-select: none;

  &:link {
    color: unset;
  }
}

body {
  background-color: $bodyBackgroundColor;
  font-size: $fontSizeM;
}

.fontSize-xs {
  @include mixins.fontVariant(xs);
}

.fontSize-s {
  @include mixins.fontVariant(s);
}

.fontSize-m {
  @include mixins.fontVariant(m);
}

.fontSize-l {
  @include mixins.fontVariant(l);
}

.fontSize-xl {
  @include mixins.fontVariant(xl);
}

.fontSize-xxl {
  @include mixins.fontVariant(xxl);
}

.fontWeight-bold {
  font-weight: 600;
}

.flex--row {
  display: flex;
  flex-direction: row;
}

.flex--column {
  display: flex;
  flex-direction: column;


  &--small {
    grid-gap: $gapXXL;
  }
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.gap--xs {
  grid-gap: $gapXS;
}

.gap--s {
  grid-gap: $gapS;
}

.gap--xl {
  grid-gap: $gapXL;
}

.gap--xxl {
  grid-gap: $gapXXL;
}

p a, span a {
  text-decoration: underline;
}

.markdown {
  p {
    @include mixins.fontVariant(m);
    line-height: 30px;
  }

  &--small {
    flex-grow: 1;

    p {
      @include mixins.fontVariant(s);
    }
  }
}

.grey {
  color: $footerLabel;
}
@use 'src/scss/mixins';
@import 'src/scss/variables';

.share {
  @include mixins.fontVariant(xs);
  color: $footerLabel;
  display: flex;
  grid-gap: $gapM;
  align-items: center;
  flex-direction: row;

  &__link {
    flex-shrink: 0;
  }

  &__actions {
    display: flex;
    grid-gap: $gapM;

    img {
      cursor: pointer;
    }
  }
}
@import 'src/scss/variables';

.content-with-filter-layout {
  display: flex;
  flex-direction: column;
  grid-gap: $gapM;

  @media all and (min-width: $desktop) {
    flex-direction: row;
  }
}
@import 'src/scss/variables';

.tags {
  display: flex;
  flex-direction: column;
  grid-gap: $gapL;

  &__list {
    display: flex;
    grid-gap: $paddingS;
    flex-wrap: wrap;
  }
}
@use 'src/scss/mixins';
@import 'src/scss/variables';

.author {
  display: flex;
  grid-gap: $gapM;
  align-items: center;

  img {
    max-width: 2rem;
    max-height: 2rem;
    border-radius: 50%;
  }

  &__name {
    @include mixins.fontVariant(xs);
    font-weight: bold;
  }

  &__publish-date {
    color: $footerLabel;
    @include mixins.fontVariant(xs)
  }

}
@use 'src/scss/mixins';
@import 'src/scss/variables';


.checkbox {
  user-select: none;
  cursor: pointer;
  padding: $paddingXS $paddingM;
  font-size: $fontSizeS;
  @include mixins.fontVariant(s);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: $shortTransition;

  input {
    display: none;
  }

  &--checked {
    position: relative;
    background-color: $activeMenu;

    &:before {
      position: absolute;
      content: "";
      width: 8px;
      height: 80%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-color: black;
      border-top-right-radius: $borderRadiusS;
      border-bottom-right-radius: $borderRadiusS;
    }
  }
}
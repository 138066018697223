@import 'src/scss/variables';

.tag {
  cursor: pointer;
  user-select: none;
  border-radius: 1rem;
  border: 1px solid $divider;
  padding: 4px 8px;
  color: $tags;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
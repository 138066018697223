@import 'src/scss/variables';

.navigation {
  display: none;
  grid-gap: 42px;
  justify-content: center;
  height: 100%;

  @media all and (min-width: $desktop) {
    display: flex;
  }
}
@use 'src/scss/mixins';
@import 'src/scss/variables';

.header {
  background-color: white;
  padding: 11px 14px;
  margin: 15px 0 20px;

  border-radius: $borderRadiusM;
  box-shadow: 4px 2px 15.5px 0 rgba(152, 152, 152, 0.09);

  position: absolute;
  top: 0;
  width: calc(100% - 40px);
  transition: $shortTransition;

  &__inner {

  }

  &--open {
    box-shadow: 0 0 1000px 1000px rgba(0, 0, 0, 0.5);
  }

  z-index: 99;

  @media all and (min-width: $tablet) {
    max-width: 640px;
    margin: 16px 0 24px;
    width: 100%;
  }

  @media all and (min-width: $desktop) {
    position: static;
    //height: $headerHeight;
    max-width: 940px;
    padding: 10px 16px;
  }

  &__actions {
    display: flex;
    grid-gap: 1rem;

    select {
      background-color: #F3F3F3;
      @include mixins.fontVariant(xs);
      border-radius: 4px;
      border: 1px solid transparent;
      padding: 8px;
      outline: none;

      &:focus,
      &:focus-visible,
      &:active {
        border: 1px solid $footerLabel;
      }

      option {
        transition: $shortTransition;

        &:hover {
          background-color: black;
        }
      }
    }
  }

  &__icon {
    @media all and (min-width: $desktop) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}